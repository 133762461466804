module.exports = [{
      plugin: require('/Users/wentzt/Code/learning/gatsby/tutorial-part-four/node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/wentzt/Code/learning/gatsby/tutorial-part-four/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('/Users/wentzt/Code/learning/gatsby/tutorial-part-four/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
